import { Controller } from '@hotwired/stimulus';
import { Timer } from "easytimer.js";

let sound

export default class extends Controller {
  static targets = [
    'countdown',
    'label',
    'result'
  ]
  
  connect() {
    this.resultTarget.classList.add('u-is-hidden')

    this.updateView = this.updateView.bind(this)
    this.revealResult = this.revealResult.bind(this)

    this.timer = new Timer();   
    this.timer.start({countdown: true, startValues: {seconds: 3}});
    this.updateView();

    this.timer.addEventListener('secondsUpdated', this.updateView);
    this.timer.addEventListener('targetAchieved', this.revealResult);
  }

  updateView() {
    this.labelTarget.innerHTML = this.timer.getTimeValues().toString((['seconds']))
    sound = new Howl({
      src: ['/bling.mp3'],
      html5: true
    });

    sound.play()
  }

  revealResult() {
    this.timer.stop();
    this.countdownTarget.classList.add('u-is-hidden')
    this.resultTarget.classList.remove('u-is-hidden')

    sound = new Howl({
      src: ['/cash.mp3'],
      html5: true
    });

    sound.play()    
  }
  
  disconnect() {
    this.timer.stop();
  }
}
