import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'checkbox' ]

  connect() {
    console.log("Connected to checkbox");
  }
  
  deselectAll(event) {
    event.preventDefault();
    this.element.querySelectorAll("input[type=checkbox]").forEach( (checkbox) => {
      checkbox.checked = false;
    });
  }
}
