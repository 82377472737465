import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [];

  closeTrigger(event) {
    event.preventDefault();
    // window.localStorage.setItem('disableTrigger', 'true');
    // Turbolinks.visit(location.toString());
    document.getElementById('trigger').classList.add('u-is-hidden')
    document.querySelector('[data-controller=codepad]').codepad.clear()
  }
}
