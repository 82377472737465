import { Controller } from '@hotwired/stimulus';
import { Timer } from "easytimer.js";

let sound

export default class extends Controller {
  static targets = [
    'input',
    'countdown',
    'slot',
    'hand',
    'startButton',
    'resetButton',
    'pauseButton',
    'resumeButton',
  ]
  static values = {
    source: String,
    autoplay: Boolean,
    path: String
  }
  
  connect() {
    console.log("Timer connected")
    this.init()

    this.updateView = this.updateView.bind(this)
    this.slotDown = this.slotDown.bind(this)
    this.startTimer = this.startTimer.bind(this)
  }

  init() {
    this.slots = 5
    this.activeSlot = 1
    this.totalSeconds = 0
    this.elapsedSeconds = 0
    this.secondsPerSlot = 0

    this.startButtonTarget.classList.remove('u-is-hidden');
    this.resetButtonTarget.classList.add('u-is-hidden');
    this.pauseButtonTarget.classList.add('u-is-hidden');
    this.resumeButtonTarget.classList.add('u-is-hidden');

    this.handTarget.setAttribute("style", `left: 0%`);

  }
  
  start() {
    // We have 5 time slots
    this.secondsPerSlot = this.inputTarget.value * 60
    this.totalSeconds = this.secondsPerSlot * 5

    this.timer = new Timer();   
    this.startTimer();
    this.activateSlot(0);
    this.updateBackend({ timeslot: this.activeSlot });

    this.startButtonTarget.classList.add('u-is-hidden');
    this.resetButtonTarget.classList.remove('u-is-hidden');
    this.pauseButtonTarget.classList.remove('u-is-hidden');
    this.resumeButtonTarget.classList.add('u-is-hidden');

    this.timer.addEventListener('secondsUpdated', this.updateView);
    this.timer.addEventListener('targetAchieved', this.slotDown);
  }
  
  pause() {
    this.timer.pause()   

    this.pauseButtonTarget.classList.add('u-is-hidden'); 
    this.resumeButtonTarget.classList.remove('u-is-hidden'); 
  }
  
  resume() {
    this.timer.start()    

    this.pauseButtonTarget.classList.remove('u-is-hidden'); 
    this.resumeButtonTarget.classList.add('u-is-hidden'); 
  }

  reset() {
    this.timer.removeEventListener('secondsUpdated');
    this.timer.removeEventListener('targetAchieved');
    this.timer.stop();   

    this.init()    
  }

  activateSlot(index) {
    this.slotTargets.forEach((slot) => slot.classList.remove('is-active'))
    this.slotTargets[index].classList.add('is-active')
    
    sound = new Howl({
      src: ['/dong.mp3'],
      html5: true
    });

    sound.play()
  }

  startTimer() {
    this.timer.start({countdown: true, startValues: {seconds: this.secondsPerSlot}});
  }

  slotDown() {
    if (this.activeSlot < this.slots) {
      this.activateSlot(this.activeSlot);
      this.activeSlot++;
      this.timer.reset();
      this.startTimer();
      this.updateBackend({ timeslot: this.activeSlot });

      document.getElementById('codepad').classList.add('u-is-hidden');
      document.getElementById('push_button').classList.remove('u-is-hidden');

    } else {
      this.endCycle()
    }
  }

  startCycle(event) {
    event.preventDefault();
    this.start();
    document.getElementById('intro').classList.add('u-is-hidden');
    document.getElementById('codepad').classList.remove('u-is-hidden');
  }

  resumeCycle(event) {
    document.getElementById('push_button').classList.add('u-is-hidden');
    document.getElementById('codepad').classList.remove('u-is-hidden');
  }
  
  endCycle() {
    document.getElementById('push_button').classList.add('u-is-hidden');
    document.getElementById('codepad').classList.add('u-is-hidden');
    document.getElementById('end-of-cycle').classList.remove('u-is-hidden');
    this.timer.stop();
  }

  endCycleManually(event) {
    event.preventDefault();
    if (window.confirm("Wollt ihr den aktuellen Zyklus wirklich beenden?")) {
      this.endCycle();
    }
  }

  updateBackend(data) {
    fetch(this.pathValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((response) => {
        const game = JSON.parse(response)
        console.log(game);
        document.getElementById('current_timeslot').innerHTML = game.timeslot;
      })
  }

  updateView() {
    let offset = 0
    let seconds = this.timer.getTotalTimeValues().seconds
    // console.log("Updaate view!");

    this.elapsedSeconds += 1

    // this.countdownTarget.innerHTML = seconds
    offset = (this.elapsedSeconds / this.totalSeconds) * 100

    this.handTarget.setAttribute("style", `left: ${offset}%`)
  }
  
  disconnect() {
    sound.pause()
    this.timer.stop();
  }
}
