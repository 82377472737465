import { Controller } from '@hotwired/stimulus';
import anime from 'animejs/lib/anime.es.js';

export default class extends Controller {
  static targets = [ 'response' ]

  // These methods are the same for now, but we will change these to be more
  // adaptive to the response. Please leave them in, so we won't need to
  // change all single views, when changing these later.

  connect() {
    console.log("Form loader init");
  }

  showSuccess(event) {
    console.log("It worked!!");

    let [data, status, xhr] = event.detail;
    // this.responseTarget.innerHTML = xhr.response;

    if (document.getElementById('trigger')) {
      anime({
        targets: '#trigger',
        translateY: 0,
        duration: 400,
        opacity: 1,
        easing: 'easeInOutCirc',
        begin: (anim) => {
          document.getElementById('trigger-content').innerHTML = xhr.response;
          document.getElementById('trigger').classList.remove('u-is-hidden');
        }
      });
    }
    // document.getElementById('trigger-content').append(fragment);
  }
  
  showErrors(event) {
    let [data, status, xhr] = event.detail;
    this.responseTarget.innerHTML = xhr.response;
  }

  closeTrigger(event) {
    event.preventDefault();
    document.getElementById('trigger').classList.add('u-is-hidden');
    document.getElementById('trigger-content').innerHTML = '';

    // const codepadController = this.application.getControllerForElementAndIdentifier(this.element, "codepad");
    // codepadController.clear();
    document.querySelector('[data-controller=codepad]').codepad.clear()
  }

}
