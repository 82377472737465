import { Controller } from '@hotwired/stimulus';
import {Howl, Howler} from 'howler';

let sound

export default class extends Controller {
  static targets = [ 'audio' ]
  static values = {
    source: String,
    autoplay: Boolean
  }

  connect() {
    if (this.autoplayValue) {
      this.play(undefined)
    }
  }
  
  play(event) {
    if (event !== undefined) {
      event.preventDefault()
    }

    sound = new Howl({
      src: [this.sourceValue],
      html5: true
    });

    // var sound = new Howl({
    //   src: [this.sourceValue],
    //   onend: () => this.triggerNext()
    // });

    sound.play()
  }  

  triggerNext() {
    console.log("Audio stopped!");
    const button = document.getElementById("trigger-btn")

    if (button) {
      button.click()
    }
  }
  
  disconnect() {
    sound.pause()
  }
}
