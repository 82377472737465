import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'response' ]

  // These methods are the same for now, but we will change these to be more
  // adaptive to the response. Please leave them in, so we won't need to
  // change all single views, when changing these later.

  showSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.responseTarget.innerHTML = xhr.response;
  }
  
  showErrors(event) {
    let [data, status, xhr] = event.detail;
    this.responseTarget.innerHTML = xhr.response;
  }

}
