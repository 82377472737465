import { Controller } from '@hotwired/stimulus';
import anime from 'animejs/lib/anime.es.js';

export default class extends Controller {
  static targets = [ 'content', 'logo' ];

  connect() {
    this.show();
  }
    
  show() {
    var timeline = anime.timeline({})

    var sound = new Howl({
      src: ['/intro.mp3'],
      html5: true
    });

    sound.play()

    timeline.add({
      targets: this.logoTarget,
      scale: 0.2,
      opacity: 0,
      duration: 0
    })
    
    timeline.add({
      targets: this.contentTarget,
      opacity: 0,
      duration: 0
    })

    timeline.add({
      targets: this.logoTarget,
      scale: 1,
      opacity: 1,
      easing: 'linear',
      duration: 7000
    })

    timeline.add({
      targets: this.logoTarget,
      scale: 1,
      opacity: 0,
      easing: 'linear',
      duration: 1000,
      delay: 2000
    })

    timeline.add({
      targets: this.contentTarget,
      opacity: 1,
      easing: 'linear',
      duration: 1000
    })
  }
}
