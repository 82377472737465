import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'selectField' ]

  connect() {
    this.toggleByKind(this.data.get("selectedKind"));
  }

  toggleFields(event) {
    var kind = event.currentTarget.value;
    this.toggleByKind(kind);
  }

  toggleByKind(kind) {
    this.selectFieldTargets.forEach( function(select) {
      const selectKinds = select.dataset.selectKind.split(', ');
      if (selectKinds.includes(kind)) {
        select.classList.remove('u-is-hidden');
        select.removeAttribute('disabled');
      } else {
        select.classList.add('u-is-hidden');
        select.setAttribute('disabled', 'disabled');
      };
    });
  }
}
