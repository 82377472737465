import { Controller } from '@hotwired/stimulus';
import Turbolinks from 'turbolinks';

export default class extends Controller {
  static targets = [ 'selectField' ]

  navigate(event) {
    console.log(this.element);
    Turbolinks.visit(`${this.element.dataset.url}?${this.element.dataset.param}=${this.element.value}`)
  }
}
