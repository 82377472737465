import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'input',
    'hiddenField',
    'successButton',
    'failureButton'
  ]

  connect() {
    this.clear();
    this.element[this.identifier] = this;
  }

  addNumber(event) {
    if (this.inputTargets[this.index]) {
      const content = event.currentTarget.innerHTML;
      this.inputTargets[this.index].innerHTML = content;  
      this.inputTargets[this.index].dataset.value = event.currentTarget.dataset.value;

      this.index++;
      this.updateCode();
    }
  }
  
  updateCode() {
    const code = this.inputTargets.map(input => input.dataset.value).join('');
    
    if (this.hasHiddenFieldTarget) {
      this.hiddenFieldTarget.value = code
    }
    
    if (this.hasSuccessButtonTarget && this.hasFailureButtonTarget) {
      const solutions = this.successButtonTargets.map(button => button.dataset.solution);
      this.successButtonTargets.forEach(button => {
        button.classList.toggle("u-hidden", code !== button.dataset.solution);
      });

      this.failureButtonTarget.classList.toggle("u-hidden", solutions.includes(code));
    }
  }
  
  clear() {
    this.inputTargets.forEach(input => {
      input.dataset.value = '';
      input.innerHTML = '';
    });  
    this.index = 0
    this.updateCode()
  }


  get index() {
    return parseInt(this.data.get("index") || 0)
  }  

  set index(value) {
    this.data.set("index", value)
  }
}
