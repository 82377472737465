import { Controller } from '@hotwired/stimulus';
import { Timer } from "easytimer.js";

export default class extends Controller {
  static targets = [ 'input', 'successButton', 'failureButton' ]

  connect() {
    this.clear();
  }

  toggleNumber(event) {
    event.target.classList.toggle('is-active')
    this.updateCode();

    this.timer = new Timer();   
    this.timer.start({countdown: true, startValues: {seconds: this.durationValue}});


    this.timer.addEventListener('secondsUpdated', this.updateView);
    this.timer.addEventListener('targetAchieved', this.releaseButton);

    this.updateView();


  }
  
  // updateCode() {
  //   const code = this.inputTargets.map(input => input.classList.contains('is-active') ? input.dataset.value : '').join('');
  //   console.log(code);
  //   const solutions = this.successButtonTargets.map(button => button.dataset.solution);

  //   this.successButtonTargets.forEach(button => {
  //     button.classList.toggle("u-hidden", code !== button.dataset.solution);
  //   });

  //   this.failureButtonTarget.classList.toggle("u-hidden", solutions.includes(code));
  // }
  
  // clear() {
  //   this.inputTargets.forEach(input => {
  //     input.classList.remove('is-active');
  //   });  
  //   this.updateCode()
  // }
}
