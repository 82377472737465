import { Controller } from '@hotwired/stimulus';
import Typewriter from 'typewriter-effect/dist/core';

let writer;
let text;

export default class extends Controller {
  static targets = [ 'content' ];

  connect() {
    console.log(this.element)
    text = this.element.innerHTML
    // console.log(text)
    this.element.innerHTML = ""
    this.toggleIndicators(true);

    writer = new Typewriter('#typewriter', {
      delay: 20,
    });

    writer
      .typeString(text)
      .callFunction(() => {
        this.toggleIndicators(false);
      })
      .start()
  }

  skipTyping() {
    this.element.innerHTML = text;
    this.toggleIndicators(false);
    console.log("Stopped typing");
  }

  toggleIndicators(state) {
    if (document.getElementById("button-set")) {
      document.getElementById("button-set").classList.toggle('u-is-disabled', state);
    }
    if (document.getElementById("asset-kind")) {
      document.getElementById("asset-kind").classList.toggle('u-is-disabled', state)
    }
  }
}
