import { Controller } from '@hotwired/stimulus';
import Turbolinks from 'turbolinks';
import anime from 'animejs/lib/anime.es.js';

export default class extends Controller {
  static targets = [ 'element' ];

  connect() {
    console.log("Content Loader connected");
    this.closeTrigger = this.closeTrigger.bind(this)

    if (this.data.has('instant')) {
      if (window.localStorage.getItem('disableTrigger') !== 'true') {
        console.log("Content Loader enabled!");
        // We add a timeout to let the page load before fetching more.
        // Could solve a racing condition, where instant triggers don't show up...
        setTimeout(() => {
          this.fetchPartial(undefined, this.data.get('instant'))
        }, 500);
      } else {
        console.log("Content Loader prevented trigger!");
        window.localStorage.removeItem('disableTrigger');
      }
    }
  }

  append(event) {
    this.fetchPartial(event, 'append');
  }

  prepend(event) {
    this.fetchPartial(event, 'prepend');
  }

  replace(event) {
    this.fetchPartial(event, 'replace');
  }

  update(event) {
    this.fetchPartial(event, 'update');
  }

  openInModal(event) {
    console.log('Fetch');

    this.fetchPartial(event, 'modal');
  }

  openAsTrigger(event) {
    this.fetchPartial(event, 'trigger');
  }

  remove(event) {
    this.elementTarget.remove()
  }

  fetchPartial(event, action) {
    let path;

    if (event) {
      event.preventDefault();
      const target = event.currentTarget;
      path = target.tagName === 'A' ? target.href : target.dataset.contentLoaderPath;
    } else {
      path = this.data.get('path');
    }

    if (document.getElementById('trigger')) {
      anime({
        targets: '#trigger',
        translateY: 240,
        duration: 400,
        opacity: 0,
        easing: 'easeInOutCirc',
        complete: (anim) => {
          // document.getElementById('trigger').classList.add('u-is-hidden');
          this.fetchFromPath(path, action);
        }
      });
    } else {
      this.fetchFromPath(path, action);
    }
  }

  closeTrigger() {
    document.getElementById('trigger').classList.add('u-is-hidden');
    document.querySelector('[data-controller=codepad]').codepad.clear()
  }

  fetchFromPath(path, action) {
    fetch(path)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((html) => {
        if (html.indexOf('redirect_to') > -1) {
          const link = html.replace('redirect_to ', '');
          console.log("Redirected!")
          Turbolinks.visit(link);
        } else if (html.indexOf('close_trigger') > -1) {
          window.localStorage.setItem('disableTrigger', 'true');
          console.log("Trigger closed!")
          // Turbolinks.visit(location.toString());
          this.closeTrigger();
        } else {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          switch(action) {
            case 'append':
              this.elementTarget.append(fragment);
              break;
            case 'prepend':
              this.elementTarget.prepend(fragment);
              break;
            case 'replace':
              this.elementTarget.replaceWith(fragment);
              break;
            case 'update':
              this.elementTarget.innerHTML = '';
              this.elementTarget.append(fragment);
              break;
            case 'modal':
              document.getElementById('modal-content').classList.remove('is-wide');
              document.getElementById('modal-content').innerHTML = '';
              document.getElementById('modal-content').append(fragment);
              document.getElementById('modal').classList.remove('u-is-hidden');
              if (document.querySelector("[autofocus]")) {
                document.querySelector("[autofocus]").focus();
              }
              break;
            case 'trigger':
              document.getElementById('trigger-content').innerHTML = '';
              document.getElementById('trigger-content').append(fragment);
              document.getElementById('trigger').classList.remove('u-is-hidden');

              break;
          }

          if (document.getElementById('trigger')) {
            anime({
              targets: '#trigger',
              translateY: 0,
              duration: 400,
              opacity: 1,
              easing: 'easeInOutCirc',
              begin: (anim) => {
                document.getElementById('trigger').classList.remove('u-is-hidden');
              }
            });
          }
        }
      });
    }
  }
