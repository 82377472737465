import Rails from '@rails/ujs';

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'selectField' ]

  connect() {
    window.dragarea = {}
    window.dragarea.offset = [0, 0];  
    window.dragarea.isDown = false;
    window.dragarea.draggedElement = null;
    window.dragarea.mousePosition = {};
  }

  startDragging(event) {
    event.preventDefault();
    
    const item = event.currentTarget;
    
    window.dragarea.isDown = true;
    window.dragarea.draggedElement = item;
    window.dragarea.offset = [
      item.offsetLeft - (event.clientX || event.touches[0].clientX),
      item.offsetTop - (event.clientY || event.touches[0].clientY)
    ];
  }
  
  stopDragging(event) {
    if (window.dragarea.draggedElement != null) {
      const top = window.dragarea.draggedElement.offsetTop;
      const left = window.dragarea.draggedElement.offsetLeft;
      const object = window.dragarea.draggedElement.dataset.object;
      
      Rails.ajax({
        type: "PATCH",
        dataType: "json",
        url: window.dragarea.draggedElement.dataset.path,
        data: `${object}[top]=${top}&${object}[left]=${left}`,
        success: (response) => {
          console.log("Success!", response);
        },
        error: (response) => {
          console.log("Failure!");
        },
      });
    }

    window.dragarea.isDown = false;
    window.dragarea.draggedElement = null;
  }

  checkAndDrag(event) {
    if (window.dragarea.isDown) {
      window.dragarea.mousePosition = {
        x: event.clientX || event.touches[0].clientX,
        y: event.clientY || event.touches[0].clientY
      };

      const pos_x = (window.dragarea.mousePosition.x + window.dragarea.offset[0])
      const pos_y = (window.dragarea.mousePosition.y + window.dragarea.offset[1])
      window.dragarea.draggedElement.style.left = pos_x + 'px';
      window.dragarea.draggedElement.style.top = pos_y + 'px';
    }
  }
}
