import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    content: String,
    target: String
  }

  connect() {
    document.getElementById(this.targetValue).innerHTML = this.contentValue
  }
}
