import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'toggleable' ]
  static values = {
    toggleableId: String
  }

  toggle(event) {
    event.preventDefault()
    
    if (this.hasToggleableIdValue) {
      console.log(this.toggleableIdValue);
      document.getElementById(this.toggleableIdValue).classList.toggle('u-is-hidden')
    } else {
      this.toggleableTargets.forEach((t) => t.classList.toggle('u-is-hidden'))
    }
  }
}
