import { Controller } from '@hotwired/stimulus';
import anime from 'animejs/lib/anime.es.js';

export default class extends Controller {
  static targets = [ 'device', 'homescreen', 'display' ];

  connect() {
    console.log("Tablet connected");
  }
  
  navigate(event) {
    event.preventDefault();
    this.fetchPartial(event, 'modal');
  }
  
  show() {
    console.log("Show tablet");
    document.getElementById('tablet').classList.remove('u-is-hidden');
    
    const homescreen = document.getElementById('homescreen').content.cloneNode(true);
    document.getElementById('display').innerHTML = '';
    document.getElementById('display').appendChild(homescreen);

    anime({
      targets: '.tablet__device',
      translateX: 900,
      translateY: 400,
      duration: 0
    })
    
    anime({
      targets: '#tablet',
      background: "rgba(0,0,0,0%)",
      duration: 0
    })

    anime({
      targets: '.tablet__aiven',
      scale: 0.5,
      duration: 0
    })

    anime({
      targets: '.tablet__device',
      translateX: 90,
      translateY: 40,
      easing: 'easeOutQuad',
      duration: 400
    })

    anime({
      targets: '#tablet',
      background: "rgba(91,136,185,70%)",
      easing: 'linear',
      duration: 200
    })

    anime({
      targets: '.tablet__aiven',
      scale: 1,
      rotate: 360,
      duration: 2000,
      delay: 100
    })
  }

  fetchPartial(event, action) {
    let path = event.currentTarget.href;

    fetch(path)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html);

        this.deviceTarget.innerHTML = '';
        this.deviceTarget.append(fragment);
      });
  }

  close(event) {
    event.preventDefault();
    this.element.classList.add('u-is-hidden');
  }
}
