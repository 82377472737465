import { Controller } from '@hotwired/stimulus';
import { Timer } from "easytimer.js";

let sound

export default class extends Controller {
  static targets = [
    'countdown',
    'label'
  ]

  static values = {
    duration: Number
  }
  
  connect() {
    console.log("Interlude connected")

    document.getElementById('interlude-wrapper').classList.add('u-is-disabled')


    this.updateView = this.updateView.bind(this)
    this.releaseButton = this.releaseButton.bind(this)

    this.timer = new Timer();   
    this.timer.start({countdown: true, startValues: {seconds: this.durationValue}});
    this.updateView();

    this.timer.addEventListener('secondsUpdated', this.updateView);
    this.timer.addEventListener('targetAchieved', this.releaseButton);
  }

  updateView() {
    this.labelTarget.innerHTML = this.timer.getTimeValues().toString((['minutes', 'seconds']))
  }

  releaseButton() {
    this.timer.stop();
    document.getElementById('interlude-wrapper').classList.remove('u-is-disabled')

    sound = new Howl({
      src: ['/bling.mp3'],
      html5: true
    });

    sound.play()
  }
  
  disconnect() {
    this.timer.stop();
  }
}
