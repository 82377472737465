import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'field', 'fields', 'radio' ]

  connect() {
    this.toggleByKind(this.data.get("selectedKind"));
  }

  toggleFields(event) {
    var kind = event.currentTarget.dataset.kind;
    this.toggleByKind(kind);
  }

  toggleByKind(kind) {
    this.fieldTargets.forEach( function(field) {
      if (field.dataset.kind !== kind) {
        field.setAttribute('disabled', 'disabled');
      } else {
        field.removeAttribute('disabled');
      };
    });

    this.fieldsTargets.forEach( function(fields) {
      if (fields.dataset.kind !== kind) {
        fields.classList.add('u-is-hidden');
      } else {
        fields.classList.remove('u-is-hidden');
      };
    });

    this.radioTargets.forEach( function(radio) {
      if (radio.dataset.kind !== kind) {
        radio.removeAttribute('checked');
      } else {
        radio.setAttribute('checked', 'checked');
      };
    });
  }
}
