import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'template',
    'content',
    'title',
    'skipTriggers',
    'costs',
    'requirements'
  ];

  remove(event) {
    event.stopPropagation();
    event.preventDefault();
    const row = event.currentTarget.closest(".cols");
    row.remove();
    
    this.reindex();
  }
  
  removeExisting(event) {
    event.stopPropagation();
    event.preventDefault();
    const path = event.target.href;

    fetch(path, { method: 'DELETE' })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((json) => {
      const response = JSON.parse(json);
      const row = event.target.closest(".cols");
      const hidden = document.querySelector(`input[type="hidden"][value="${response.id}"]`);
      
      row.remove();
      hidden.remove();
    })
  }

  add(event) {
    event.preventDefault();
    const template = this.templateTarget.content;
    const newLine = document.importNode(template, true);
    this.contentTarget.appendChild(newLine);

    const inputs = this.contentTarget.getElementsByTagName('INPUT');
    inputs[inputs.length - 1].focus();

    this.reindex();
  }

  reindex() {
    this.titleTargets.forEach(function(item, index) {
      const object_name = item.dataset.object
      const new_name = `${object_name}[answers_attributes][${index}][title]`
      item.setAttribute('name', new_name);
    });
    
    this.skipTriggersTargets.forEach(function(item, index) {
      const object_name = item.dataset.object
      const new_name = `${object_name}[answers_attributes][${index}][skip_triggers]`
      item.setAttribute('name', new_name);
    });

    this.costsTargets.forEach(function(item, index) {
      const object_name = item.dataset.object
      const new_name = `${object_name}[answers_attributes][${index}][costs]`
      item.setAttribute('name', new_name);
    });

    this.requirementsTargets.forEach(function(item, index) {
      const object_name = item.dataset.object
      const new_name = `${object_name}[answers_attributes][${index}][requirements]`
      item.setAttribute('name', new_name);
    });
  }
}
