import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'content', 'wrapper' ]

  close(event) {
    if (!this.contentTarget.contains(event.target)) {
      this.contentTarget.innerHTML = '';
      this.wrapperTarget.classList.add('u-is-hidden');
    }
  }

  forcedClose(event) {
    event.preventDefault()
    this.contentTarget.innerHTML = '';
    this.wrapperTarget.classList.add('u-is-hidden');
  }
}
