import { Controller } from '@hotwired/stimulus'
import Sortable from "sortablejs"
import Rails from '@rails/ujs';

export default class extends Controller {
 connect() {
   this.sortable = Sortable.create(this.element, {
     group: 'shared',
     handle: '.js-drag-handle',
     animation: 150,
     dragClass: 'is-dragging',
     draggable: '.draggable',
     onEnd: this.end.bind(this)
   })
  }
  
  end(event) {
    this.element.classList.add('is-locked')

    let url = event.item.dataset.path;
    let data = new FormData();
    data.append("position", event.newDraggableIndex + 1);

    Rails.ajax({
      url: url,
      type: 'PATCH',
      data: data
    })
  }
}
