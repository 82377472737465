import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'tab', 'content' ]

  initialize() {
    this.showTab()
  }

  switch(event) {
    event.preventDefault()
    this.index = this.tabTargets.indexOf(event.currentTarget)
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const content = this.contentTargets[index]
      tab.classList.toggle("is-active", index == this.index)
      content.classList.toggle("u-hidden", index != this.index)
    })
    this.contentTargets[this.index].querySelector('[autofocus]').focus()
  }

  get index() {
    return parseInt(this.data.get("index") || 0)
  }

  set index(value) {
    this.data.set("index", value)
    this.showTab()
  }
}
