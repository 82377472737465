import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'toggleable' ]

  toggleNavigation(event) {
    event.preventDefault()
    document.body.classList.toggle('is-playing')
  }
}
