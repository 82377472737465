import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ ]

  connect() {
    const containers = JSON.parse(window.localStorage.getItem('scrollPositions')) || {};

    for (let id in containers) {
      if(document.getElementById(id)) {
        document.getElementById(id).scrollTop = containers[id];
      }
    }
  }
    
  saveScrollPosition(event) {
    const container = event.currentTarget;
    var positions = JSON.parse(window.localStorage.getItem('scrollPositions')) || {};

    positions[container.id] = container.scrollTop;
    window.localStorage.setItem('scrollPositions', JSON.stringify(positions));
  }
}
