document.addEventListener("turbolinks:request-start", function() {
  window.prevPageYOffset = window.pageYOffset;
  window.prevPageXOffset = window.pageXOffset;
  window.prevURL = window.location.href;

  // // Remove all audio tags to avoid replayed audio from cache
  // if (document.body.audio) {
  //   document.body.audio.pause()
  // }
});

document.addEventListener("turbolinks:load", function() {
  if (window.prevURL == window.location.href) {
    window.scrollTo(window.prevPageXOffset, window.prevPageYOffset);
  }
});
